const colors = {
  primary: {
    main: '#4B7E89',
    dark: '#365B63',
    light: '#84B1BB',
  },
  secondary: {
    main: '#4C558A',
    dark: '#414976',
    light: '#6973AC',
  },
  white: '#FEFEFE'
};

export { colors };