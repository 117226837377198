import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav } from 'react-bootstrap'
import { Github, Linkedin } from 'react-bootstrap-icons';
import { colors } from '../global/theme';

const Header = ({ siteTitle }) => (
  <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top" >
    <Navbar.Brand href="/">{siteTitle}</Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="ml-auto">
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/work">Work</Nav.Link>
        <Nav.Link href="/about">About</Nav.Link>
        <Nav.Link href="/contact">Contact</Nav.Link>
      </Nav>
      <Nav className="navbar-nav">
        <Nav.Link 
          href="https://github.com/cooperluetje" 
          as={props => (
            <a href={props.href} className="nav-link" target="_blank" rel="noreferrer">
              <Github size={30} color={colors.white} />
            </a>
          )} 
        />
        <Nav.Link 
          href="https://www.linkedin.com/in/cluetje/"
          as={props => (
            <a href={props.href} className="nav-link" target="_blank" rel="noreferrer">
              <Linkedin size={30} color={colors.white} />
            </a>
          )} 
        />
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
